<template>
  <div class="bg-white py-4 mt-10">
    <div class="container">
      <p class="text-center mb-0 text-muted small">
        Copyright &copy; {{ year }}
        <a
          href="https://github.com/nusantaraskuadteknologi"
          target="_blank"
          rel="noopener noreferrer"
          class="fw-semibold"
        >
          Nusantara Skuad Teknologi
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
